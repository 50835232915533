//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: undefined,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};