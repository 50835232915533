//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ImageThumbnail',
  props: {
    src: {
      type: undefined,
      required: true,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: ''
    }
  }
};