import _slicedToArray from "/workspace/node_modules/@babel/runtime/helpers/esm/slicedToArray";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import _ from 'lodash';
export default {
  name: 'FormSelect',
  props: {
    id: {
      type: String,
      required: false,
      default: 'select'
    },
    options: {
      type: undefined,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    title: {
      type: undefined,
      required: true
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    pushTags: {
      type: Boolean,
      required: false,
      default: false
    },
    reduce: {
      type: Function,
      required: true
    },
    createOptions: {
      type: Function,
      required: false,
      default: undefined
    },
    rules: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: undefined,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    search: {
      type: Function,
      required: false,
      default: function _default(value) {
        return value;
      }
    },
    filterable: {
      type: Boolean,
      default: true
    },
    getOptionKey: {
      type: Function,
      default: function _default(option) {
        if (option && option.id) {
          return option.id;
        }

        if (option && option.value) {
          return option.value;
        }

        return JSON.stringify(option);
      }
    },
    comment: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput: function handleInput() {
      this.$emit('input', this.content);
    },
    getOptionLabel: function getOptionLabel(option) {
      var _$at = _.at(option, this.label),
          _$at2 = _slicedToArray(_$at, 1),
          result = _$at2[0];

      return result;
    }
  }
};