import _slicedToArray from "/workspace/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Compressor from 'compressorjs';
export default {
  name: 'FormInputPicture',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: '',
      required: false
    },
    minHeight: {
      type: String,
      default: null,
      required: false
    },
    processing: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: function data() {
    return {
      coverImageFile: null,
      url: ''
    };
  },
  methods: {
    compressor: function compressor(file) {
      var that = this;
      var compressor = new Compressor(file, {
        maxWidth: 1920,
        maxHeight: 1080,
        mimeType: 'image/webp',
        quality: 0.8,
        success: function success(result) {
          var compressedFile = new File([result], result.name, {
            type: result.type
          });
          that.coverImageFile = compressedFile;
          that.url = URL.createObjectURL(compressedFile);
          that.$emit('uploaded', compressedFile);
        },
        error: function error(err) {
          console.warn(err.message);
        }
      });
      return compressor;
    },
    onChangeCoverImage: function onChangeCoverImage() {
      var _this$$refs$coverImag = _slicedToArray(this.$refs.coverImageFileInput.files, 1),
          file = _this$$refs$coverImag[0];

      this.compressor(file);
    },
    dragOverCoverImage: function dragOverCoverImage(event) {
      event.preventDefault(); // Add some visual fluff to show the user can drop its files

      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragLeaveCoverImage: function dragLeaveCoverImage(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    dropCoverImage: function dropCoverImage(event) {
      event.preventDefault();
      this.$refs.coverImageFileInput.file.files = event.dataTransfer.files;
      this.onChangeCoverImage(); // Trigger the onChange event manually
      // Clean up

      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
};